import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Section from "../section"
import globet from "../../images/testimonialGlobe.png"
import { mediaQueries } from "../../utils/presets"

/** @jsx jsx */
import { jsx } from "@emotion/core"
import styled from "@emotion/styled"

import Slider from "react-slick"
import { FormattedMessage } from "react-intl"

const Testimonials = ({ node_locale }) => {
  const settings = {
    dots: false,
    autoplay: true,
    infinite: true,
    speed: 900,
    autoplaySpeed: 10000,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          dots: true,
        },
      },
    ],
  }
  const data = useStaticQuery(graphql`
    query {
      testimonialUs: allContentfulTestimonials(
        filter: { node_locale: { eq: "en-US" } }
      ) {
        edges {
          node {
            author
            travelName
            date(formatString: "DD MMMM, YYYY")
            testimonial {
              testimonial
            }
          }
        }
      }
      testimonialEs: allContentfulTestimonials(
        filter: { node_locale: { eq: "es" } }
      ) {
        edges {
          node {
            author
            travelName
            date(formatString: "DD MMMM, YYYY")
            testimonial {
              testimonial
            }
          }
        }
      }
      testimonialsBg: file(name: { eq: "testimonialsBg" }) {
        childImageSharp {
          id
          fluid(maxWidth: 1920) {
            src
          }
        }
      }
    }
  `)
  const baseBg = data.testimonialsBg.childImageSharp.fluid.src

  let TestimonialsBg = styled.div({
    backgroundImage: `url(${baseBg})`,
    [mediaQueries.md]: {
      minHeight: 400,
    },
    backgroundPosition: `center center`,
    backgroundSize: `cover`,
    backgroundRepeat: `no-repeat`,
    marginBottom: `3rem`,
  })

  const itemsByNode =
    node_locale === `es` ? data.testimonialEs : data.testimonialUs
  const testimonialsItems = itemsByNode.edges.map((item, index) => (
    <div key={index.toString()} className={``}>
      <blockquote
        css={{
          color: `#fff`,
          fontSize: `14px`,
          marginTop: 15,
        }}
      >
        {item.node.testimonial.testimonial}
        <span
          css={{
            display: `block`,
            marginTop: 15,
          }}
        >
          {item.node.author} {item.node.date && `- ${item.node.date}`}{" "}
        </span>
        <span
          css={{
            display: `block`,
          }}
        >
          {item.node.travelName && (
            <>
              <FormattedMessage id="Recommends" />: {item.node.travelName}
            </>
          )}
        </span>
      </blockquote>
    </div>
  ))

  return (
    <TestimonialsBg>
      <Section class={`testimonials`}>
        <div className="columns">
          <div className="column">
            <img
              alt="Customers Testimonials"
              css={{
                maxWidth: 420,
                width: `100%`,
              }}
              src={globet}
            />
          </div>
          <div className="column slick-testimonials">
            <Slider {...settings}>{testimonialsItems}</Slider>
          </div>
        </div>
      </Section>
    </TestimonialsBg>
  )
}

export default Testimonials
