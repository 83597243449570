import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Home from "../components/Content/Home/home"

export default props => {
  const l = props

  return (
    <React.Fragment>
      <Seo
        title={`Tours en Nicaragua`}
        description={`Ofrecemos un servicio completo de Tours en Nicaragua. Descubre los destinos más populares en Nicaragua como Granada, Masaya, Volcan Mombacho con nosotros.`}
      />
      <Home location={props.location} langKey={props.pageContext.langKey} />
    </React.Fragment>
  )
}
