import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import { Parallax } from "react-scroll-parallax"

/** @jsx jsx */
import { jsx } from "@emotion/core"

const Hero = () => {
  const data = useStaticQuery(graphql`
    query {
      hero: contentfulHero {
        heroImage {
          fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
    }
  `)
  return (
    <div
      css={{
        position: `relative`,
        overflow: `hidden`,
      }}
    >
      <Parallax y={[-30, 30]} tagOuter="figure">
        <Img alt={`Welcome to Nicaragua`} fluid={data.hero.heroImage.fluid} />
      </Parallax>
    </div>
  )
}

export default Hero
