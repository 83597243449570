import React from "react"
import * as PropTypes from "prop-types"
import Layout from "../../layout"
import TravelItem from "../../travel-item/travel-item"

import { FormattedMessage, FormattedHTMLMessage } from "react-intl"
import Hero from "../../hero"

import { graphql, useStaticQuery, Link } from "gatsby"

import Section from "../../section"
import Img from "gatsby-image"
import BgMap from "../../BgMap"
import ModalPromo from "../../modalPromo"

import Icon247 from "../../../images/customer-service.svg"
import IconBus from "../../../images/bus.svg"
import IconNature from "../../../images/nature.svg"
import IconAward from "../../../images/award.svg"
import IconBussines from "../../../images/business.svg"
import IconBeach from "../../../images/beach.svg"

/** @jsx jsx */
import { jsx } from "@emotion/core"

import Testimonials from "../../testimonials"
import Slider from "react-slick"

import { categories, mediaQueries } from "../../../utils/presets"
import lang from "../../../data/languages"

import useLocalStorage from "../../../utils/hooks/useLocalStorage"

const Home = props => {
  const [firstVisit, setFirstVisit] = useLocalStorage("firstVisit", false)

  let _slider = ``

  const next = () => {
    _slider.slickNext()
  }
  const previous = () => {
    _slider.slickPrev()
  }
  const currentLang =
    props.langKey === lang.defaultLangKey ? `` : `/${props.langKey}`
  const data = useStaticQuery(graphql`
    query {
      us: allContentfulTravels(
        limit: 3
        filter: {
          isFeatured: { eq: true }
          travelType: { slug: { eq: "destinations" } }
          node_locale: { eq: "en-US" }
        }
      ) {
        edges {
          node {
            id
            title
            shortDescription
            slug
            node_locale
            isHotDeals
            price
            dealsPrice
            photo {
              fluid(maxWidth: 400) {
                ...GatsbyContentfulFluid
              }
            }
            travelType {
              slug
              typeTitle
            }
          }
        }
      }
      es: allContentfulTravels(
        limit: 3
        filter: {
          isFeatured: { eq: true }
          travelType: { slug: { eq: "destinations" } }
          node_locale: { eq: "es" }
        }
      ) {
        edges {
          node {
            id
            title
            shortDescription
            slug
            node_locale
            isHotDeals
            price
            dealsPrice
            photo {
              fluid(maxWidth: 400) {
                ...GatsbyContentfulFluid
              }
            }
            travelType {
              slug
              typeTitle
            }
          }
        }
      }
      whyChosse: file(name: { eq: "why-choose" }) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      catTours: file(name: { eq: "cat-tours" }) {
        childImageSharp {
          id
          fluid(maxWidth: 398) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      catDestinations: file(name: { eq: "cat-destinations" }) {
        childImageSharp {
          id
          fluid(maxWidth: 398) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      catCircuits: file(name: { eq: "cat-circuits" }) {
        childImageSharp {
          id
          fluid(maxWidth: 398) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      catToursEs: file(name: { eq: "cat-tours-es" }) {
        childImageSharp {
          id
          fluid(maxWidth: 398) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      catDestinationsEs: file(name: { eq: "cat-destinations-es" }) {
        childImageSharp {
          id
          fluid(maxWidth: 398) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      events: file(name: { eq: "events" }) {
        childImageSharp {
          id
          fluid(maxWidth: 398) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      eventsEs: file(name: { eq: "events-es" }) {
        childImageSharp {
          id
          fluid(maxWidth: 398) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      catCircuitsEs: file(name: { eq: "cat-circuits-es" }) {
        childImageSharp {
          id
          fluid(maxWidth: 398) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      deals: file(name: { eq: "deals" }) {
        childImageSharp {
          id
          fluid(maxWidth: 398) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      dealsEs: file(name: { eq: "deals-es" }) {
        childImageSharp {
          id
          fluid(maxWidth: 398) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)
  const usTravelsEdges = props.langKey === `es` ? data.es.edges : data.us.edges
  const catDestinations =
    props.langKey === `es`
      ? data.catDestinationsEs.childImageSharp.fluid
      : data.catDestinations.childImageSharp.fluid
  const catTours =
    props.langKey === `es`
      ? data.catToursEs.childImageSharp.fluid
      : data.catTours.childImageSharp.fluid
  const events =
    props.langKey === `es`
      ? data.eventsEs.childImageSharp.fluid
      : data.events.childImageSharp.fluid
  const catCircuits =
    props.langKey === `es`
      ? data.catCircuitsEs.childImageSharp.fluid
      : data.catCircuits.childImageSharp.fluid

  const deals =
    props.langKey === `es`
      ? data.dealsEs.childImageSharp.fluid
      : data.deals.childImageSharp.fluid

  const settingsCatSlider = {
    dots: false,
    arrows: false,
    autoplay: true,
    infinite: true,
    speed: 800,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  }
  return (
    <Layout location={props.location}>
      <Hero />
      <div className="alternatives-index">
        <div className="gradient" />
        <Section>
          <div className="columns">
            <div className="column is-3">
              <div className="sect-title">
                <FormattedMessage id="Alternatives" />
                <h1>
                  <FormattedMessage id="WeKnow" />
                  <br />
                  Nicaragua
                </h1>
              </div>
              <div className="sect-content">
                <p>
                  <FormattedMessage id="WeAreText" />
                </p>
                <p>
                  <FormattedMessage id="WeAreText2" />
                </p>
                <div
                  css={{
                    textAlign: "center",
                  }}
                >
                  <section
                    css={{
                      position: `realtive`,
                      opacity: 0,
                      [mediaQueries.md]: {
                        opacity: 1,
                      },
                    }}
                  >
                    <button
                      title={`Previous`}
                      className="slick-arrow slick-prev"
                      onClick={previous}
                    >
                      Previous
                    </button>
                    <button
                      title={`next`}
                      className="slick-arrow slick-next"
                      onClick={next}
                    >
                      Next
                    </button>
                  </section>
                </div>
              </div>
            </div>
            <div
              className="column"
              css={{
                position: `relative`,
              }}
            >
              <div
                className="carousel-wrap"
                css={{
                  position: `absolute`,
                  width: `100%`,
                  zIndex: 33,
                }}
              >
                <Slider ref={c => (_slider = c)} {...settingsCatSlider}>
                  <div className="carousel-cell-40">
                    <Link to={`${currentLang}/${categories.tours}`}>
                      <Img alt={categories.tours} fluid={catTours} />
                    </Link>
                  </div>
                  <div className="carousel-cell-40">
                    <Link to={`${currentLang}/deals`}>
                      <Img alt={`deals`} fluid={deals} />
                    </Link>
                  </div>
                  <div className="carousel-cell-40">
                    <Link to={`${currentLang}/${categories.destinations}`}>
                      <Img
                        alt={categories.destinations}
                        fluid={catDestinations}
                      />
                    </Link>
                  </div>

                  <div className="carousel-cell-40">
                    <Link to={`${currentLang}/events`}>
                      <Img alt={`events`} fluid={events} />
                    </Link>
                  </div>
                </Slider>
              </div>
            </div>
          </div>
        </Section>
      </div>
      <BgMap>
        <Section>
          <div
            className="has-text-centered"
            css={{
              paddingTop: 155,
              marginBottom: 50,
            }}
          >
            <h2 className="primary-title">
              <FormattedMessage id="popularDestination" />
            </h2>
            <FormattedMessage id="EnjoyPhrase" />
          </div>
          <div className="columns">
            {usTravelsEdges.map(({ node }, i) => (
              <TravelItem node={node} key={node.id} />
            ))}
          </div>
          <div className={`has-text-centered`}>
            <Link
              to={`${currentLang}/${categories.destinations}`}
              className="button is-link"
            >
              <FormattedMessage id="viewAll" />
            </Link>
          </div>
        </Section>
      </BgMap>
      <Section class={`why-choose`} pb0={true}>
        <div
          className={`has-text-centered`}
          css={{
            marginBottom: `120px`,
          }}
        >
          <h2 className="primary-title">
            <FormattedMessage id="WhyChooseUs" />
            <br />
            Nicaragua’s Best Guides?
          </h2>
          <FormattedHTMLMessage id="WhyPhrase" />
        </div>

        <div className={`columns choose-items`}>
          <div className={`column`}>
            <Img
              alt="Why choose"
              css={{
                width: `100%`,
              }}
              fluid={data.whyChosse.childImageSharp.fluid}
            />
          </div>
          <div className={`column`}>
            <div className={`columns`}>
              <div className={`column has-text-centered`}>
                <img alt={`Assistance`} width={80} src={Icon247} />
                <h3>
                  <FormattedMessage id="Assistance" />
                </h3>
              </div>
              <div className={`column has-text-centered`}>
                <img alt={`Departures`} width={80} src={IconBus} />
                <h3>
                  <FormattedMessage id="Departures" />
                </h3>
              </div>
              <div className={`column has-text-centered`}>
                <img alt={`Environmentally`} width={80} src={IconNature} />
                <h3>
                  <FormattedMessage id="Environmentally" />
                </h3>
              </div>
            </div>
            <br />
            <br />
            <div className={`columns has-text-centered`}>
              <div className={`column`}>
                <img alt={`Quality`} width={80} src={IconAward} />
                <h3>
                  <FormattedMessage id="Quality" />
                </h3>
              </div>

              <div className={`column has-text-centered`}>
                <img alt={`No fee`} width={80} src={IconBussines} />
                <h3>
                  <FormattedMessage id="Nofee" />
                </h3>
              </div>
              <div className={`column has-text-centered`}>
                <img alt={`Handpicked`} width={80} src={IconBeach} />
                <h3>
                  <FormattedMessage id="Handpicked" />
                </h3>
              </div>
            </div>
          </div>
        </div>
      </Section>

      <Testimonials node_locale={props.langKey} />
      {!firstVisit ? <ModalPromo setStorage={setFirstVisit} /> : null}
    </Layout>
  )
}
export default Home
