import React, { useState } from "react"
import * as PropTypes from "prop-types"
/** @jsx jsx */
import { jsx } from "@emotion/core"
import { graphql, useStaticQuery, navigate } from "gatsby"
import Img from "gatsby-image"
import styled from "@emotion/styled"
import { FormattedMessage, FormattedHTMLMessage } from "react-intl"

const ModalPromo = props => {
  const data = useStaticQuery(graphql`
    query {
      promoPopup: file(name: { eq: "ngbPromo" }) {
        childImageSharp {
          id
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)

  const [isModalActive, setIsModalActive] = useState(true)

  const closeModal = () => {
    setIsModalActive(!isModalActive)
    props.setStorage(true)
  }

  const goToDealsEs = () => {
    props.setStorage(true)
    navigate("/es/deals")
  }

  const goToDeals = () => {
    props.setStorage(true)
    navigate("/deals")
  }

  return (
    <section>
      <div
        css={{
          marginTop: 40,
          textAlign: `center`,
        }}
      >
        <button
          to="/"
          onClick={() => setIsModalActive(!isModalActive)}
          className="button is-link"
        >
          <FormattedMessage id="SendRequest" />
        </button>
      </div>
      <div className={`modal ${isModalActive ? "is-active" : ""}`}>
        <div className="modal-background" />
        <div className="modal-content section">
          <Box>
            <Img alt="Promos" fluid={data.promoPopup.childImageSharp.fluid} />
            <LinkDealsEs onClick={goToDealsEs} />
            <LinkDealsEn onClick={goToDeals} />
          </Box>
        </div>
        <button
          onClick={closeModal}
          className="modal-close is-large"
          aria-label="close"
        />
      </div>
    </section>
  )
}

export default ModalPromo

const Box = styled.div`
  border-radius: 6px;
  color: #4a4a4a;
  display: block;
`
const LinkDealsEn = styled.button`
  position: absolute;
  top: 145px;
  left: 0;
  height: 70px;
  width: 139px;
  background: transparent;
  border: none;
  cursor: pointer;
`
const LinkDealsEs = styled.button`
  position: absolute;
  top: 71px;
  left: 0;
  height: 70px;
  width: 139px;
  background: transparent;
  border: none;
  cursor: pointer;
`
